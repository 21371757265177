@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

html {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  position: relative;
  font-size: 16px;
  overflow: hidden;
}

@media (min-width: 768px) {
  body {
    font-size: 16px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

button {
  border: 0;
  background: none;
  cursor: pointer;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0%;
  right: 0;
  overflow: hidden;
  overflow-y: auto;
}

.App {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
